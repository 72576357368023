import { Component } from 'react';
import PropTypes from 'prop-types';
import CourseReservationForm from './CourseReservationForm';
import { setSaleTotalValue } from '../../stores/MBAcademy';

class CourseReservationFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittingForm: false,
      errorSendingForm: false,
      showOverlay: false,
      baseParams: {},
    };

    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.getFirstConversionParams = this.getFirstConversionParams.bind(this);
    this.sendFirstConversion = this.sendFirstConversion.bind(this);
    this.getSecondConversionParams = this.getSecondConversionParams.bind(this);
    this.sendSecondConversion = this.sendSecondConversion.bind(this);
  }

  handleCloseOverlay() {
    const { handlePostConversion } = this.props;

    this.setState({ showOverlay: false });

    if (handlePostConversion) {
      handlePostConversion();
    }
  }

  getFirstConversionParams() {
    const { model, shouldShowFullConversionData } = this.props;

    const {
      name,
      cpf,
      cnpj,
      email,
      phone,
      mailing,
      phoning,
      whatsapping,
      neighborhood,
      address,
      cep,
      courseTitle: product,
      workload,
      courseValue,
      formType,
      modality,
      userUf,
      userCity,
    } = window.store.MBAcademy.getState();

    this.setState({
      baseParams: {
        category: 'direct_sale',
        bait: '-8',
        brand: 'Mercedes-Benz do Brasil',
        product,
        name,
        email,
        phone,
        mailing,
        phoning,
        whatsapping,
        userUf,
        userCity,
        neighborhood,
        address,
        cep,
        workload: `${workload} horas`,
        courseValue: `R$ ${courseValue}`,
        formType: formType === 'person' ? 'Pessoa Física' : 'Pessoa Jurídica',
        modality,
        model,
        shouldShowFullConversionData,
      },
    });

    if (formType === 'person') {
      this.setState(prevState => ({
        baseParams: {
          ...prevState.baseParams,
          cpf,
        },
      }));
    } else if (formType === 'enterprise') {
      this.setState(prevState => ({
        baseParams: {
          ...prevState.baseParams,
          cnpj,
        },
      }));
    }
  }

  getSecondConversionParams() {
    const {
      formType,
      modality,
      courseDateTime,
      uf,
      city,
      investmentValue,
      subsidiary,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      dataPermissions,
      acceptAdhesionForm,
      courseValue,
    } = window.store.MBAcademy.getState();

    this.setState(prevState => ({
      baseParams: {
        ...prevState.baseParams,
        uf,
        city,
        investmentValue,
        subsidiary,
        dataPermissions,
        acceptAdhesionForm,
      },
    }));

    if (modality === 'Virtual') {
      const saleTotalValue =
        this.unmaskedValue(courseValue) * Number(numberOfVacancies);
      const saleTotalValueFormatted = this.maskedValue(saleTotalValue);

      setSaleTotalValue({
        saleTotalValue: saleTotalValueFormatted,
      });

      this.setState(prevState => ({
        baseParams: {
          ...prevState.baseParams,
          courseDateTime,
          numberOfVacancies,
          saleTotalValue: `R$ ${saleTotalValueFormatted}`,
        },
      }));
    } else {
      setSaleTotalValue({
        saleTotalValue: courseValue,
      });

      this.setState(prevState => ({
        baseParams: {
          ...prevState.baseParams,
          saleTotalValue: `R$ ${courseValue}`,
        },
      }));
    }

    if (formType === 'enterprise') {
      this.setState(prevState => ({
        baseParams: {
          ...prevState.baseParams,
          optingSimpleNational,
          stateRegistration,
          municipalInscription,
        },
      }));
    }
  }

  sendFirstConversion() {
    this.getFirstConversionParams();

    const params = this.state.baseParams;

    return window.serviceConversion
      .convert(params)
      .then(() => {})
      .catch(error => {
        console.error(error);
      });
  }

  sendSecondConversion() {
    this.setState({ isSubmittingForm: true }, () => {
      this.getSecondConversionParams();

      let status = 'error';
      let data = null;

      const params = this.state.baseParams;

      return window.serviceConversion
        .convert(params)
        .then(() => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: false,
          });
          status = 'success';
          data = params;
        })
        .catch(error => {
          this.setState({
            isSubmittingForm: false,
            errorSendingForm: true,
          });
          data = error;
          console.error(error);
        })
        .then(() => {
          document.dispatchEvent(
            new CustomEvent('new_conversion', {
              detail: {
                status,
                data,
              },
            }),
          );
          this.setState({
            showOverlay: true,
          });
        });
    });
  }

  unmaskedValue(number) {
    const formatedNumber = Number(
      number.replace(/[^0-9,-]+/g, '').replace(',', '.'),
    );

    return formatedNumber;
  }

  maskedValue(number) {
    return number.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  }

  render() {
    const {
      titleForm,
      mainPhrase,
      courseTitle,
      courseSubtitle,
      workload,
      modality,
      courseValue,
      linkPrivacyPolicy,
      linkAdhesionForm,
      courseDatesTimes,
      totalVacanciesOffered,
    } = this.props;

    const { isSubmittingForm, showOverlay, errorSendingForm } = this.state;

    return (
      <CourseReservationForm
        titleForm={titleForm}
        mainPhrase={mainPhrase}
        courseTitle={courseTitle}
        courseSubtitle={courseSubtitle}
        workload={workload}
        modality={modality}
        courseValue={courseValue}
        linkPrivacyPolicy={linkPrivacyPolicy}
        linkAdhesionForm={linkAdhesionForm}
        courseDatesTimes={courseDatesTimes}
        totalVacanciesOffered={totalVacanciesOffered}
        handleCloseOverlay={this.handleCloseOverlay}
        isSubmittingForm={isSubmittingForm}
        sendFirstConversion={this.sendFirstConversion}
        sendSecondConversion={this.sendSecondConversion}
        showOverlay={showOverlay}
        errorSendingForm={errorSendingForm}
      />
    );
  }
}

CourseReservationFormApp.defaultProps = {
  titleForm: '',
  mainPhrase: '',
  courseTitle: '',
  courseSubtitle: '',
  workload: '',
  modality: '',
  courseValue: '',
  model: '',
  linkPrivacyPolicy: '',
  linkAdhesionForm: '',
  courseDatesTimes: [],
  totalVacanciesOffered: '',
  handlePostConversion() {},
  shouldShowFullConversionData: false,
};

CourseReservationFormApp.propTypes = {
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  courseTitle: PropTypes.string,
  courseSubtitle: PropTypes.string,
  workload: PropTypes.string,
  modality: PropTypes.string,
  courseValue: PropTypes.string,
  model: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  linkAdhesionForm: PropTypes.string,
  courseDatesTimes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  totalVacanciesOffered: PropTypes.string,
  handlePostConversion: PropTypes.func,
  shouldShowFullConversionData: PropTypes.bool,
};

export default CourseReservationFormApp;
