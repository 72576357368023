import MBAcademy from '../auto/stores/MBAcademy';

(function () {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  let store = {
    MBAcademy
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach((c) => (window.store[c] = store[c]()));
})();
