import { Component } from 'preact';
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import CustomCheck from '../CustomCheck';
import FormMessageOverlay from '../FormMessageOverlay';
import SubmitButton from '../SubmitButton';
import validationFactory from '%/utils/validation';
import IMask from 'imask';
import { phoneMasks } from '%/utils/masks';
import handleInputChange from '%/utils/handleInputChange';
import InputCpf from '../InputCpf';
import LocationFields from '../Inputs/Location';
import {
  setBaseUserInfo,
  setReservationInfo,
  setCourseInfo,
} from '../../stores/MBAcademy';

class CourseReservationForm extends Component {
  constructor(props) {
    super(props);

    this.resetState();

    this.handleInputChange = handleInputChange.bind(this);
    this.handleContactOptionsChange =
      this.handleContactOptionsChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleUserStateChange = this.handleUserStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleUserCityChange = this.handleUserCityChange.bind(this);
    this.maskFactory = this.maskFactory.bind(this);
    this.setFieldMasks = this.setFieldMasks.bind(this);
    this.populateVacanciesOffered = this.populateVacanciesOffered.bind(this);
    this.saveBaseUserInfo = this.saveBaseUserInfo.bind(this);
    this.saveCourseInfo = this.saveCourseInfo.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.uniqByKeepFirst = this.uniqByKeepFirst.bind(this);
    this.checksAreValid = this.checksAreValid.bind(this);
  }

  componentDidMount() {
    const { courseTitle, workload, courseValue } = this.props;

    this.populateVacanciesOffered();
    this.validator = validationFactory(`#course-reservation-form`);

    setCourseInfo({
      courseTitle,
      workload,
      courseValue,
    });
  }

  resetState() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      cpf: '',
      cnpj: '',
      userUf: null,
      userCity: null,
      neighborhood: '',
      address: '',
      cep: '',
      courseDateTime: '',
      numberOfVacancies: 0,
      vacanciesOffered: [],
      phoning: false,
      mailing: true,
      whatsapping: false,
      contactOptions: 'on',
      dataPermissions: false,
      acceptAdhesionForm: false,
      optingSimpleNational: 'nao',
      stateRegistration: '',
      municipalInscription: '',
      formType: '',
      modality: this.props.modality,
      uf: null,
      city: null,
      units: this.props.units,
      investmentValue: '',
      subsidiaries: [],
      subsidiary: '',
      subsidiaryDisabled: false,
      step: 0,
      tryToConvert: false,
    });
  }

  clearbaseUserInfo() {
    this.setState({
      formType: '',
      name: '',
      email: '',
      phone: '',
      cpf: '',
      cnpj: '',
      userUf: null,
      userCity: null,
      neighborhood: '',
      address: '',
      cep: '',
      phoning: false,
      mailing: true,
      whatsapping: false,
      contactOptions: 'on',
    });

    setBaseUserInfo({
      formType: null,
      name: null,
      email: null,
      phone: null,
      cpf: null,
      cnpj: null,
      userUf: null,
      userCity: null,
      neighborhood: null,
      address: null,
      cep: null,
      phoning: null,
      mailing: null,
      whatsapping: null,
    });
  }

  maskFactory({ field, state, masks }) {
    if (!field) {
      return;
    }

    const fieldMask = IMask(field, {
      mask: [...masks],
    });

    fieldMask.on('accept', () => this.setState({ [state]: fieldMask.value }));

    return fieldMask;
  }

  setFieldMasks() {
    const { step } = this.state;

    if (step == 1) {
      this.maskFactory({
        field: this.phoneInput,
        state: 'phone',
        masks: phoneMasks,
      });

      this.maskFactory({
        field: this.cnpjInput,
        state: 'cnpj',
        masks: [{ mask: '00.000.000/0000-00' }],
      });

      this.maskFactory({
        field: this.cepInput,
        state: 'cep',
        masks: [{ mask: '00000-000' }],
      });
    } else if (step == 2) {
      this.maskFactory({
        field: this.stateRegistrationInput,
        state: 'stateRegistration',
        masks: [{ mask: '000000000000000' }],
      });

      this.maskFactory({
        field: this.municipalInscriptionInput,
        state: 'municipalInscription',
        masks: [{ mask: '00000000000' }],
      });
    }
  }

  populateVacanciesOffered() {
    const { totalVacanciesOffered } = this.props;
    const { vacanciesOffered } = this.state;

    if (totalVacanciesOffered) {
      for (let index = 1; index <= totalVacanciesOffered; index++) {
        vacanciesOffered.push({
          label: index,
          value: index,
        });
      }
    }
  }

  saveBaseUserInfo() {
    const {
      formType,
      modality,
      name,
      cpf,
      cnpj,
      email,
      phone,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      mailing,
      phoning,
      whatsapping,
    } = this.state;

    const baseUserInfo = {
      formType,
      modality,
      name,
      cpf,
      cnpj,
      email,
      phone,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      mailing,
      phoning,
      whatsapping,
    };

    setBaseUserInfo(baseUserInfo);
  }

  saveCourseInfo() {
    const {
      courseDateTime,
      uf,
      city,
      investmentValue,
      subsidiary,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      dataPermissions,
      acceptAdhesionForm,
    } = this.state;

    const reservationInfo = {
      courseDateTime,
      uf,
      city,
      investmentValue,
      subsidiary,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      dataPermissions,
      acceptAdhesionForm,
    };

    setReservationInfo(reservationInfo);
  }

  uniqByKeepFirst(a, key) {
    let seen = new Set();
    return a.filter(item => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  handleStepChange(formType = '', isStepIncrease = true) {
    const lastStep = 2;
    const { step } = this.state;

    if (formType != '') {
      this.setState({
        formType: formType,
      });
    }

    if (isStepIncrease) {
      const dataIsValid = this.handleValidation();

      if (dataIsValid && step <= lastStep) {
        this.setState({
          step: step + 1,
        });
      }
    } else {
      this.setState({
        step: step - 1,
      });

      if (step == 1) {
        this.clearbaseUserInfo();
      }
    }

    // Necessário devido ao carregamento do novo HTML
    setTimeout(() => {
      this.setFieldMasks();
    }, 250);
  }

  handleValidation() {
    const { step } = this.state;
    const { sendFirstConversion, sendSecondConversion } = this.props;

    if (step == 2) {
      this.setState({
        tryToConvert: true,
      });
    }

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (!formIsValid) {
      return formIsValid;
    }

    if (step == 1) {
      this.saveBaseUserInfo();
      sendFirstConversion();
    } else if (step == 2) {
      if (!this.checksAreValid()) {
        return false;
      }

      this.saveCourseInfo();
      sendSecondConversion();
    }

    return true;
  }

  handleContactOptionsChange(e) {
    this.handleInputChange(e);
    this.state.contactOptions = this.contactOptionsValue() ? 'on' : '';
  }

  handleStateChange(e) {
    const uf = e.target.value;
    this.setState({ uf, subsidiary: '', subsidiaries: [] });
  }

  handleUserStateChange(e) {
    const { userUf } = window.store.MBAcademy.getState();

    const uf = e.target.value ? e.target.value : userUf;

    this.setState({
      userUf: uf,
    });
  }

  handleCityChange(e) {
    const city = e.target.value;

    this.setState({ city, subsidiary: '' });

    if (this.state.uf && this.state.city) {
      this.setState({ subsidiaryDisabled: true });

      let apiQuery = [
        { 'q[state_cont]': this.state.uf },
        { 'q[city_cont]': this.state.city },
      ];

      apiQuery.push({ 'q[truck_eq]': true });

      window.autoServiceApi.getServedRegion(apiQuery).then(res => {
        let subsidiaries = res.entries.map(region => ({
          label: region.display_name,
          value: region.display_name,
          city: region.city,
        }));

        let subsidiariesUniq = this.uniqByKeepFirst(
          subsidiaries,
          sub => sub.label,
        );

        // TODO atualizar filtro do apicasso para remover nomes parciais, ex: SP cidade Arujá que retorna Guarujá.
        subsidiariesUniq = subsidiariesUniq.filter(
          s => s.city.length == this.state.city.length,
        );

        this.setState({
          subsidiaries: subsidiariesUniq,
          subsidiary: '',
          subsidiaryDisabled: false,
        });
      });
    }
  }

  handleUserCityChange(e) {
    const { userCity } = window.store.MBAcademy.getState();

    const city = e.target.value ? e.target.value : userCity;

    this.setState({
      userCity: city,
    });
  }

  handleInputChangeWithState(e, state) {
    const { value } = e.target;

    this.setState({
      [state]: value,
    });
  }

  contactOptionsValue() {
    return !!(
      this.state.phoning ||
      this.state.mailing ||
      this.state.whatsapping
    );
  }

  checksAreValid() {
    const { dataPermissions, acceptAdhesionForm } = this.state;

    return dataPermissions && acceptAdhesionForm;
  }

  render() {
    const {
      titleForm,
      mainPhrase,
      courseTitle,
      courseSubtitle,
      workload,
      modality,
      courseValue,
      investmentOptions,
      linkPrivacyPolicy,
      linkAdhesionForm,
      courseDatesTimes,
      handleCloseOverlay,
      isSubmittingForm,
      showOverlay,
      errorSendingForm,
    } = this.props;

    const {
      formType,
      step,
      name,
      cpf,
      cnpj,
      email,
      phone,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      mailing,
      whatsapping,
      phoning,
      contactOptions,
      uf,
      city,
      investmentValue,
      subsidiaries,
      subsidiary,
      subsidiaryDisabled,
      stateRegistration,
      municipalInscription,
      dataPermissions,
      acceptAdhesionForm,
      courseDateTime,
      numberOfVacancies,
      vacanciesOffered,
      optingSimpleNational,
      tryToConvert,
    } = this.state;

    return (
      <form
        className="conversion-form"
        id="course-reservation-form"
        onSubmit={e => e.preventDefault()}
        ref={form => (this.form = form)}
        novalidate
      >
        <FormMessageOverlay
          handleClose={handleCloseOverlay}
          isVisible={showOverlay}
          type={errorSendingForm ? 'error' : 'success'}
          successMessage={'Solicitação de reserva enviada com sucesso!'}
          content={{
            success: {
              iconClass: 'icon icon-check-mark',
              topMessage: 'Solicitação de reserva enviada com sucesso!',
              bottomMessage:
                'Em breve um de nossos consultores entrará em contato com você',
            },
          }}
        />
        <header>
          <div className="conversion-form__header-phrase">
            <h2>{titleForm}</h2>
            <p>{mainPhrase}</p>
          </div>
        </header>

        <div className="form-conversion__body">
          {step == 0 && (
            <fieldset>
              <h6 className="pb-2">CURSO</h6>

              <h5 className="course-title pb-1">{courseTitle}</h5>

              <p>{courseSubtitle}</p>

              <div className="more-info">
                <h6>
                  Carga horária:
                  <span> {workload} horas </span>
                </h6>

                <h6>
                  Modalidade:
                  <span> {modality} </span>
                </h6>

                <h6>
                  {modality == 'Virtual'
                    ? 'Investimento por aluno:'
                    : 'Investimento por turma:'}

                  <span> R$ {courseValue} </span>
                </h6>
              </div>

              <hr className="my-3" />

              <button
                type="button"
                className="btn button button--large button--primary w-100 mb-2"
                onClick={() => {
                  this.handleStepChange('person');
                }}
              >
                SOU PESSOA FÍSICA
              </button>

              <button
                type="button"
                className="btn button button--large button--primary--outline w-100 mb-0"
                onClick={() => {
                  this.handleStepChange('enterprise');
                }}
              >
                SOU PESSOA JURÍDICA
              </button>
            </fieldset>
          )}

          {step == 1 && (
            <div>
              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={name}
                  type="text"
                  className="form-control"
                  name="name"
                  required
                  placeholder={
                    formType == 'person' ? 'Nome' : 'Nome - Pessoa Jurídica'
                  }
                  maxLength={80}
                  data-bouncer-target="#invalid-name"
                />
                <div id="invalid-name" className="invalid-feedback" />
              </div>

              {formType == 'person' ? (
                <InputCpf handleChange={this.handleInputChange} value={cpf} />
              ) : (
                <div className="form-group">
                  <input
                    onChange={this.handleInputChange}
                    value={cnpj}
                    type="cnpj"
                    className="form-control"
                    name="cnpj"
                    placeholder="CNPJ"
                    required
                    data-bouncer-target="#invalid-cnpj"
                    ref={cnpjInput => (this.cnpjInput = cnpjInput)}
                  />
                  <div id="invalid-cnpj" className="invalid-feedback" />
                </div>
              )}

              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={email}
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="E-mail"
                  maxLength={80}
                  required
                  data-bouncer-target="#invalid-email"
                />
                <div id="invalid-email" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={phone}
                  ref={phoneInput => (this.phoneInput = phoneInput)}
                  className="form-control"
                  name="phone"
                  type="phone"
                  data-bouncer-target="#invalid-phone"
                  placeholder="Telefone/Whatsapp (DDD+número)"
                  required
                />
                <div id="invalid-phone" className="invalid-feedback" />
              </div>

              <LocationFields
                uf={userUf}
                handleStateChange={this.handleUserStateChange}
                city={userCity}
                handleCityChange={this.handleUserCityChange}
                showExternalCity={true}
                externalCity={userCity}
              />

              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={neighborhood}
                  className="form-control"
                  name="neighborhood"
                  type="neighborhood"
                  data-bouncer-target="#invalid-neighborhood"
                  placeholder="Bairro"
                  required
                />
                <div id="invalid-neighborhood" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={address}
                  className="form-control"
                  name="address"
                  type="address"
                  data-bouncer-target="#invalid-address"
                  placeholder="Endereço completo"
                  required
                />
                <div id="invalid-address" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <input
                  onChange={this.handleInputChange}
                  value={cep}
                  className="form-control"
                  name="cep"
                  type="cep"
                  data-bouncer-target="#invalid-cep"
                  placeholder="CEP"
                  ref={cepInput => (this.cepInput = cepInput)}
                  required
                />
                <div id="invalid-cep" className="invalid-feedback" />
              </div>

              <fieldset>
                <legend className="conversion-form__control-label">
                  Quero receber contato por:
                </legend>

                <div className="form-check form-check-inline">
                  <CustomCheck
                    name="mailing"
                    value="true"
                    type="checkbox"
                    isChecked={mailing}
                    disabled={true}
                  >
                    E-mail
                  </CustomCheck>
                </div>

                <div className="form-check form-check-inline">
                  <CustomCheck
                    name="whatsapping"
                    value="true"
                    isChecked={whatsapping}
                    onChangeCheckable={this.handleContactOptionsChange}
                    type="checkbox"
                  >
                    Whatsapp
                  </CustomCheck>
                </div>

                <div className="form-check form-check-inline">
                  <CustomCheck
                    name="phoning"
                    value="true"
                    isChecked={phoning}
                    onChangeCheckable={this.handleContactOptionsChange}
                    type="checkbox"
                  >
                    Telefone
                  </CustomCheck>
                </div>

                <div className="form-group contact-options">
                  <input
                    type="text"
                    className="form-control d-none"
                    name="contact-options"
                    required
                    placeholder="Opções de contato"
                    data-bouncer-target="#invalid-contact-options"
                    value={contactOptions}
                    checked={contactOptions}
                  />
                  <div
                    id="invalid-contact-options"
                    className="invalid-feedback"
                  />
                </div>
              </fieldset>

              <button
                type="button"
                className="btn button button--large button--primary w-100 mt-3 mb-2"
                onClick={() => {
                  this.handleStepChange();
                }}
              >
                AVANÇAR
              </button>

              <button
                type="button"
                className="btn button button--large button--primary--outline w-100 mb-0"
                onClick={() => {
                  this.handleStepChange('', false);
                }}
              >
                VOLTAR
              </button>

              <hr className="my-2" />

              <footer className="form-conversion__footer pt-1">
                Estamos comprometidos em resguardar suas informações. Conheça
                nossa <a href={linkPrivacyPolicy}>Política de privacidade</a>.
              </footer>
            </div>
          )}

          {step == 2 && (
            <div>
              <fieldset>
                {courseDatesTimes && modality == 'Virtual' && (
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={e =>
                        this.handleInputChangeWithState(e, 'courseDateTime')
                      }
                      name="course-date-time"
                      options={courseDatesTimes}
                      value={courseDateTime}
                      placeholderValue="Selecione a data e horário do curso"
                      searchEnabled={false}
                      required={true}
                      errorMessageDisplay="#invalid-course-date-time"
                      ref={courseDateTimeInput =>
                        (this.courseDateTimeInput = courseDateTimeInput)
                      }
                    />

                    <div
                      id="invalid-course-date-time"
                      className="invalid-feedback is-invalid-course-date-time"
                    ></div>
                  </div>
                )}

                {courseDatesTimes && modality === 'Presencial' && (
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={e =>
                        this.handleInputChangeWithState(e, 'investmentValue')
                      }
                      name="investment-value"
                      options={investmentOptions}
                      value={investmentValue}
                      placeholderValue="Selecione o valor de investimento"
                      searchEnabled={false}
                      required
                      errorMessageDisplay="#invalid-investment-value"
                    />

                    <div
                      id="invalid-investment-value"
                      className="invalid-feedback is-invalid-investment-value"
                    />
                  </div>
                )}

                <LocationFields
                  handleStateChange={this.handleStateChange}
                  uf={uf}
                  handleCityChange={this.handleCityChange}
                  city={city}
                />

                <div className="form-group subsidiary-select">
                  <CustomSelect
                    handleSelectChange={this.handleInputChange}
                    name="subsidiary"
                    options={subsidiaries}
                    value={subsidiary}
                    shouldSort={false}
                    placeholderValue="Concessionária de preferência"
                    searchEnabled={false}
                    disable={subsidiaryDisabled}
                  />
                </div>
                <div className="form-group subsidiary-options">
                  <input
                    type="text"
                    className="form-control d-none"
                    name="subsidiary-options"
                    required
                    placeholder="Opções de Consessionaria"
                    data-bouncer-target="#invalid-subsidiary-options"
                    value={subsidiary}
                    checked={subsidiary}
                  />
                  <div
                    id="invalid-subsidiary-options"
                    className="invalid-feedback"
                  ></div>
                </div>

                {courseDatesTimes && modality == 'Virtual' && (
                  <div className="form-group">
                    <CustomSelect
                      handleSelectChange={e =>
                        this.handleInputChangeWithState(e, 'numberOfVacancies')
                      }
                      name="number-vacancies"
                      options={vacanciesOffered}
                      value={numberOfVacancies}
                      placeholderValue="Reservar vagas"
                      searchEnabled={false}
                      required={true}
                      errorMessageDisplay="#invalid-number-vacancies"
                    />

                    <div
                      id="invalid-number-vacancies"
                      className="invalid-feedback is-invalid-number-vacancies"
                    ></div>
                  </div>
                )}
              </fieldset>

              {formType == 'enterprise' && (
                <fieldset>
                  <div className="form-group">
                    <fieldset>
                      <label className="bold-label">
                        Você é optante do Simples Nacional
                      </label>
                      <div className="form-check form-check-inline">
                        <CustomCheck
                          checkStyle="dark"
                          name="opting-simple-national"
                          value="sim"
                          isChecked={optingSimpleNational === 'sim'}
                          onChangeCheckable={e =>
                            this.handleInputChangeWithState(
                              e,
                              'optingSimpleNational',
                            )
                          }
                          type="radio"
                        >
                          Sim
                        </CustomCheck>
                      </div>

                      <div className="form-check form-check-inline">
                        <CustomCheck
                          checkStyle="dark"
                          name="opting-simple-national"
                          value="nao"
                          isChecked={optingSimpleNational === 'nao'}
                          onChangeCheckable={e =>
                            this.handleInputChangeWithState(
                              e,
                              'optingSimpleNational',
                            )
                          }
                          type="radio"
                        >
                          Não
                        </CustomCheck>
                      </div>

                      <div class="pt-1 pb-1">
                        <small>
                          Texto informativo sobre a opção de Simples Nacional.
                        </small>
                      </div>
                    </fieldset>
                  </div>

                  <div className="form-group">
                    <input
                      onChange={e =>
                        this.handleInputChangeWithState(e, 'stateRegistration')
                      }
                      value={stateRegistration}
                      className="form-control"
                      name="state-registration"
                      type="state-registration"
                      data-bouncer-target="#invalid-state-registration"
                      placeholder="Inscrição Estadual"
                      ref={stateRegistrationInput =>
                        (this.stateRegistrationInput = stateRegistrationInput)
                      }
                      required
                    />
                    <div
                      id="invalid-state-registration"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      onChange={e =>
                        this.handleInputChangeWithState(
                          e,
                          'municipalInscription',
                        )
                      }
                      value={municipalInscription}
                      className="form-control"
                      name="municipal-inscription"
                      type="municipal-inscription"
                      data-bouncer-target="#invalid-municipal-inscription"
                      placeholder="Inscrição Municipal"
                      ref={municipalInscriptionInput =>
                        (this.municipalInscriptionInput =
                          municipalInscriptionInput)
                      }
                    />
                    <div
                      id="invalid-municipal-inscription"
                      className="invalid-feedback"
                    />
                  </div>
                </fieldset>
              )}

              <fieldset className="mt-0 mb-2">
                <div className="form-check form-check-inline conversion-form__data-permissions-field">
                  <CustomCheck
                    name="dataPermissions"
                    value="true"
                    isChecked={dataPermissions}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                  >
                    Ao reservar você confirma o interesse em receber informações
                    sobre campanhas
                  </CustomCheck>
                </div>

                {tryToConvert && !dataPermissions && (
                  <div
                    id="invalid-data-permissions"
                    className="invalid-feedback is-invalid-data-permissions"
                  >
                    <div
                      className="error-message"
                      id="bouncer-error_data-permissions"
                    >
                      Por favor, selecione esse campo
                    </div>
                  </div>
                )}
              </fieldset>

              <hr className="my-3" />

              <fieldset className="mb-2">
                <div className="form-check form-check-inline accept-adhesion-form">
                  <CustomCheck
                    name="acceptAdhesionForm"
                    value="true"
                    isChecked={acceptAdhesionForm}
                    onChangeCheckable={this.handleInputChange}
                    type="checkbox"
                  >
                    <span class="pr-1">
                      Li e aceito o{' '}
                      <a href={linkAdhesionForm} target="_blank">
                        Termo de adesão
                      </a>
                    </span>
                  </CustomCheck>
                </div>

                {tryToConvert && !acceptAdhesionForm && (
                  <div
                    id="invalid-accept-adhesion-form"
                    className="invalid-feedback is-invalid-accept-adhesion-form"
                  >
                    <div
                      className="error-message"
                      id="bouncer-error_accept-adhesion-form"
                    >
                      Por favor, selecione esse campo
                    </div>
                  </div>
                )}
              </fieldset>

              <hr className="my-3" />

              <SubmitButton
                className="btn button button--large button--primary w-100 mt-2 mb-2"
                label={'SOLICITAR RESERVA'}
                isSubmitting={isSubmittingForm}
                handleClick={this.handleValidation}
              />

              <button
                type="button"
                className="btn button button--large button--primary--outline w-100 mt-2"
                onClick={() => {
                  this.handleStepChange('', false);
                }}
              >
                VOLTAR
              </button>

              <hr className="my-3" />

              <footer className="form-conversion__footer pt-1">
                Estamos comprometidos em resguardar suas informações. Conheça
                nossa <a href={linkPrivacyPolicy}>Política de privacidade</a>.
              </footer>
            </div>
          )}
        </div>
      </form>
    );
  }
}

// default props
CourseReservationForm.defaultProps = {
  titleForm: '',
  mainPhrase: '',
  courseTitle: '',
  courseSubtitle: '',
  workload: '',
  modality: '',
  courseValue: '',
  linkPrivacyPolicy: '',
  linkAdhesionForm: '',
  courseDatesTimes: [],
  investmentOptions: [
    {
      label: 'Valor por turma: R$ 4.300,00',
      value: 'Valor por turma: R$ 4.300,00',
    },
    { label: '04 horas – R$750,00', value: '04 horas – R$750,00' },
    { label: '08 horas – R$1.500,00', value: '08 horas – R$1.500,00' },
    { label: '16h horas - R$3.000,00', value: '16h horas - R$3.000,00' },
    { label: '24h horas - R$4.500,00', value: '24h horas - R$4.500,00' },
    { label: '32h horas - R$6.000,00', value: '32h horas - R$6.000,00' },
    { label: '40h horas - R$7.500,00', value: '40h horas - R$7.500,00' },
  ],
  totalVacanciesOffered: '',
  handleCloseOverlay: function () {},
  isSubmittingForm: false,
  sendFirstConversion: function () {},
  sendSecondConversion: function () {},
  showOverlay: false,
  errorSendingForm: false,
  redirectLink: '',
};

CourseReservationForm.propTypes = {
  titleForm: PropTypes.string,
  mainPhrase: PropTypes.string,
  courseTitle: PropTypes.string,
  courseSubtitle: PropTypes.string,
  workload: PropTypes.string,
  modality: PropTypes.string,
  courseValue: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  linkAdhesionForm: PropTypes.string,
  courseDatesTimes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  investmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  totalVacanciesOffered: PropTypes.string,
  handleCloseOverlay: PropTypes.func,
  isSubmittingForm: PropTypes.bool,
  sendFirstConversion: PropTypes.func,
  sendSecondConversion: PropTypes.func,
  showOverlay: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  redirectLink: PropTypes.string,
};

export default CourseReservationForm;
