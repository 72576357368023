import { h } from 'preact';
import { render } from 'react';

import CourseReservationFormApp from '../auto/components/MBAcademy/CourseReservationFormApp';
import CourseReservationSummary from '../auto/components/MBAcademy/CourseReservationSummary';

if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) => {
    return render(vnode, parent, parent.firstElementChild);
  };

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  let components = {
    CourseReservationFormApp,
    CourseReservationSummary
  };

  Object.keys(components).forEach((c) => (window[c] = components[c]));
})();
