import { Component } from 'preact';

export default class CourseReservationSummary extends Component {
  constructor(props){
    super(props)

    const {
      formType,
      name,
      email,
      phone,
      cpf,
      cnpj,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      courseDateTime,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      modality,
      uf,
      city,
      subsidiary,
      courseValue,
      saleTotalValue,
      workload,
      courseTitle
    } = window.store.MBAcademy.getState();

    this.setState({
      formType,
      name,
      email,
      phone,
      cpf,
      cnpj,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      courseDateTime,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      modality,
      uf,
      city,
      subsidiary,
      courseValue,
      saleTotalValue,
      workload,
      courseTitle
    }) 
  }

  render() {
    const {
      formType,
      name,
      email,
      phone,
      cpf,
      cnpj,
      userUf,
      userCity,
      neighborhood,
      address,
      cep,
      courseDateTime,
      numberOfVacancies,
      optingSimpleNational,
      stateRegistration,
      municipalInscription,
      modality,
      uf,
      city,
      subsidiary,
      courseValue,
      saleTotalValue,
      workload,
      courseTitle,
    } = this.state;

    return (
      <div className="course-reservation-summary shadow-sm p-3 mb-5 bg-white rounded">
        <h2>Resumo</h2>
        <div className="row justify-content-between mx-0 align-items-baseline">
          <h6>Confira abaixo os dados da solicitação de reserva de vaga</h6>
          <h3 class="d-none">Identificador: <strong>{}</strong></h3>
        </div>
        <hr />
        <div className="course-reservation-summary-info">
          <div className="row">
            <div className="personal-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Dados Pessoais</h3>
                <div className="summary__info-item">
                  <h4 className="bold-label">Tipo de pessoa: </h4>
                  <p>{formType == "person" ? 'Pessoa Física' : 'Pessoa Jurídica'}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Nome: </h4>
                  <p>{name}</p>
                </div>
                <div className="summary__info-item">  
                  {formType == "person" ? (
                    <div> 
                      <h4 className="bold-label">CPF: </h4>
                      <p>{cpf}</p>
                    </div>
                  ) : (
                    <div>
                      <h4 className="bold-label">CNPJ: </h4>
                      <p>{cnpj}</p>
                    </div>
                  )}
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Email: </h4>
                  <p>{email}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Telefone/Whatsapp: </h4>
                  <p>{phone}</p>
                </div>
                {formType == "enterprise" &&
                  <div>
                    <div className="summary__info-item"> 
                      <h4 className="bold-label">Inscrição Estadual: </h4>
                      <p>{stateRegistration}</p>
                    </div>
                    <div className="summary__info-item">
                      <h4 className="bold-label">Inscrição Municipal: </h4>
                      <p>{municipalInscription != "" ? municipalInscription : "Não informado"}</p>
                    </div>
                  </div>
                }
              </div>
              <hr/>
            </div>
            <div className="select-course-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Curso selecionado</h3>
                <div className="summary__info-item">
                  <h4 className="bold-label">Curso: </h4>
                  <p>{courseTitle}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Carga Horária: </h4>
                  <p>{workload}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Modalidade: </h4>
                  <p>{modality}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Turma: </h4>
                  <p>{modality == "Virtual" ? courseDateTime : "Data e horário à definir "}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Estado: </h4>
                  <p>{uf}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Cidade: </h4>
                  <p>{city}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Concessionária de preferência: </h4>
                  <p>{subsidiary}</p>
                </div>
              </div>
              <hr/>
            </div>
            <div className="address-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Endereço cadastrado</h3>
                <div className="summary__info-item">
                  <h4 className="bold-label">UF </h4>
                  <p>{userUf}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Cidade: </h4>
                  <p>{userCity}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Bairro: </h4>
                  <p>{neighborhood}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">Endereço completo: </h4>
                  <p>{address}</p>
                </div>
                <div className="summary__info-item">
                  <h4 className="bold-label">CEP: </h4>
                  <p>{cep}</p>
                </div>
              </div>
              <hr/>
            </div>
            <div className="payment-data col-sm-12 col-md-6 d-flex flex-column justify-content-between">
              <div className="data">
                <h3>Dados de Pagamento</h3>
                  {modality == "Virtual" ? (
                    <div>
                      <div className="summary__info-item">
                        <h4 className="bold-label">Investimento por aluno: </h4>
                        <p>R$ {courseValue}</p>
                      </div>
                      <div className="summary__info-item"> 
                        <h4 className="bold-label">Quantidade de vagas solicitas para reserva: </h4>
                        <p>{numberOfVacancies}</p>
                      </div>
                      {formType == "enterprise" && 
                        <div className="summary__info-item">
                          <h4 className="bold-label">Optante pelo Simples Nacional: </h4>
                          <p>{optingSimpleNational == "sim" ? 'Sim' : 'Não'}</p>
                        </div>
                      }
                      <div className="summary__info-item">
                        <h4 className="bold-label">Valor total: </h4>
                        <p>R$ {saleTotalValue}</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="summary__info-item">
                        <h4 className="bold-label">Investimento por turma: </h4>
                        <p>R$ {courseValue}</p>
                      </div>
                      {formType == "enterprise" && 
                        <div className="summary__info-item">
                          <h4 className="bold-label">Optante pelo Simples Nacional: </h4>
                          <p>{optingSimpleNational == "sim" ? 'Sim' : 'Não'}</p>
                        </div>
                      }
                      <div className="summary__info-item">
                        <h4 className="bold-label">Valor total: </h4>
                        <p>R$ {saleTotalValue}</p>
                      </div>
                    </div>
                  )} 
              </div>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CourseReservationSummary.defaultProps = {
    formType: "tipo",
    name: "Nome",
    email: "email@email",
    phone: "(00) 00000-0000",
    cpf: "000.000.000-00",
    cnpj: "0000",
    userUf: "Estado",
    userCity: "Cidade",
    neighborhood: "Bairro",
    address: "Endereço",
    cep: "00.000-000",
    courseDateTime: "01 de Janeiro - 13h às 17h",
    numberOfVacancies: "Quantidade de vagas",
    vacanciesOffered: "",
    mailing: "",
    phoning: "",
    whatsapping: "",
    dataPermissions: "",
    optingSimpleNational: "Sim",
    stateRegistration: "000.000.000.000",
    municipalInscription: "",
    modality: "Virtual",
    uf: "UF",
    city: "Cidade",
    subsidiary: "MB Dealer",
    courseValue: "0,00",
    saleTotalValue: "0,00",
    courseTitle: ""
  };
  
